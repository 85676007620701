@import "custom";
@import "variables";
@import 'styles';

.App {
  display: flex;
  flex-direction: column;
  .menu {
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    margin: 20px;
    .submenu {
      display: flex;
    }
    a {
      padding: 20px;
      text-decoration: none;
      color: #8a996d;
      font-size: 20px;
      &:hover {
        color: #3D550C;
    }
    &.active {
      color: #3D550C;
    }
    }
  }
}

/* mobile styling */

@media (max-width: 1024px) {

  .App {
    justify-content: space-between;
    .menu {
      padding: 10px;
      .web-name {
        color: $darkgreen;
        font-family: "ArtisualDecoRegular";
      }
      a.mobile-name {
          font-size: 16px;
          font-weight: 800;
          height: 100%;
          img {
            height: 40px;
          }
      }
      a {
        font-size: 14px;
        padding: 0 4px;
      }
    }
  
    .App-logo {
      height: 60vmin;
      pointer-events: none;
    }
    
    .App-header {
      .header-text {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
          h1 {
            color: $lightgreen;
            font-size: 40px;
            margin: 0;
          }
          p {
            color: $darkgreen;
            font-size: 16px;
            opacity: .8;
            text-align: center;
            &.small-text {
              font-size: 16px;
            }
          }
          button {
            padding: 12px;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #fff;
            background: $lightgreen;
            color: #fff;
            width: 200px;
          }
          a {
            text-decoration: none;
            color: #e2806a;
            &.btn.btn-primary {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

/* web styling */

@media (min-width: 1024px) { 
  .App {
    padding-bottom: 0;
    .menu {
      .mobile-name {
        display: none;
      }
      .web-name {
        font-size: 50px;
        font-family: "ArtisualDecoRegular";
        img {
          height: 168px;
        }
      }
      .submenu {
        display: flex;
      }
      a {
        padding: 20px;
        text-decoration: none;
        color: #8a996d;
        font-family: "ArtisualDecoRegular";;
        font-size: 26px;
        &.btn.btn-primary {
          color: #fff;
        }
        &.web-name {
          color: #3D550C;
          &:hover {
            color: #2a3b09;
          }
        }
      }
      .sub-menu {
        a {
          font-family: "Brandon";
        }
      }
    }
  }
  
  .App-logo {
    height: 60vmin;
    pointer-events: none;
  }
}
