/* Standard Colors for Project */

$white: #fff;
$salmon: #e2806a;
$lightgreen: #3D550C;
$darkgreen: #012b09;
$lightblue: #41abb7;
$black: #000;
$parchment: #f7ebdb;

/*
 * Keyframes
 */

 @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
@keyframes fadeInUp {
0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
}

100% {
    opacity: 1;
    transform: none;
}
}

@keyframes fadeInRight {
0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

100% {
    opacity: 1;
    transform: none;
}
}
