@import "../custom";
@import "../variables";

.honda-page {
    padding: 0 60px;
    a {
        text-decoration: none;
        .back-section {
            color: $lightgreen;
            font-weight: 700;
            display: flex;
            align-items: center;
            cursor: pointer;
            .icon {
                padding-right: 4px;
                font-size: 16px;
            }
            &:hover {
                color: $darkgreen;
                cursor: pointer;
            }
        }
    }
    .work-img {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
            margin: 20px;
        }
        &.row {
            display: flex;
            padding: 0;
        }
    }
    h1 {
        color: $lightgreen;
        margin: 0;
    }
    .row {
        padding: 20px 40px;
        display: flex;
        .team {
            display: flex;
            flex-direction: column;
            width: 100%;
            .bold {
                font-weight: 700;
            }
            p.members {
                margin: 4px;
            }
            .title {
                margin-bottom: 20px;
            };
        }
        .title {
            font-size: 26px;
            font-weight: 700;
            color: $lightgreen;
        }
        .page-title {
            margin-bottom: 0;
        }
        p {
            color: $lightgreen;
            font-size: 20px;
            &.solution {
                font-size: 24px;
                color: $lightgreen;
            }
            &.back-section {
                cursor: pointer;
            }
        }
        .summary {
            width: 100%;
        }
        li {
            color: $lightgreen;
            font-size: 20px;
            padding: 4px;
        }
        img {
            height: 300px;
        }
        &.page-header {
            img {
                margin-left: 40px;
            }
                .text {
                    padding-right: 46px;
                    .summary {
                        margin-top: 20px;
                        font-size: 28px;
                    }
                    .date {
                        color: $lightgreen;
                        font-weight: 700;
                        font-size: 16px;
                    }
                    .overview {
                        font-size: 20px;
                        font-weight: 700;
                        margin: 10px 0;
                    }
                }
            }
    }
    .methods {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .work-img {
            display: flex;
            justify-content: center;
            width: 100%;
        }
        h2 {
            color: $lightgreen;
            font-size: 20px;
            padding-top: 20px;
        }
        .insight {
            display: flex;
            align-items: center;
            padding: 20px 40px;
            h2 {
                margin-left: 10px;
            }
        }
    }
    .svg-inline--fa {
        color: $lightgreen;
        font-size: 32px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) { 
    .honda-page {
        padding: 0;
        .work-img {
            justify-content: center;
            align-items: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            img {
                height: 200px;
            }
        }
    }
}

@media (max-width: 767px) { 
    .honda-page {
        padding: 0;
        .work-img {
            justify-content: center;
            align-items: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            img {
                height: 20vh;
            }
            &.row {
                padding: 0;
                img {
                    padding: 0;
                }
                &.page-header {
                    .text {
                        padding-right: 40px;
                    }
                    img {
                        margin-left: 0;
                    }
                }
            }
        }
        .row {
            p {
                color: $lightgreen;
                &.solution {
                    font-size: 16px;
                    color: $lightgreen;
                }
                &.back-section {
                    cursor: pointer;
                }
            }
            &.page-header {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .text {
                    padding-right: 40px;
                }
                img {
                    margin-left: 0;
                    height: 200px;
                    padding-top: 10px;
                }
            }
        }
    }
}