@import 'variables';

/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal
);

.btn.btn-primary {
    background-color: $lightgreen;
    padding: 16px;
    font-size: 20px;
    width: 200px;
}