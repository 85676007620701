@import 'variables';

.skills-page {
    padding: 0 40px;
    a {
        text-decoration: none;
    }
    h2 {
        color: #3D550C;
        font-size: 24px;
        margin: 4px;
    }
    h3 {
        color: #3D550C;
        font-size: 20px;
        margin: 4px
    }
    h1 {
        padding: 20px;
        color: #3D550C;
        margin-top: 10vh;
        color: #3D550C;      
        font-family: "Brandon";
        font-size: 44px;
        font-weight: 500;
    }
    .skills-text {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .row {
            height: 70%;
            display: flex;
            p {
                padding-bottom: 10px;
                margin: 4px;
                font-size: 20px;
                color: #012b09;
            }
        }
    }
    .card {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
        margin: 10px;
        width: 40vw;
        height: 25vw;
        background: $parchment;
        cursor: pointer;
        display: flex;
        border-radius: 30px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        &:hover {
            transform: scale3d(1.006,1.006,1);
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            background-color: #c1c7b5;            
            }
            .row {
                display: flex;
            }
            .card-img {
                width: 30%;
                padding: 20px;
                align-self: center;
                &.honda {
                    width: 40%
                }
            }
            .card-text {
                padding: 20px;      
                display: flex;          
                flex-direction: column;
                justify-content: space-between;
                .desc {
                    font-size: 16px;
                }
            }                
            .methods {
                padding-top: 10px;
                display: flex;
                padding: 20px;
                font-family:'Segoe UI', Tahoma, Verdana, sans-serif;
                div {
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    margin-right: 8px;
                    padding: 8px;
                    background-color: $lightgreen;
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
}

@media (max-width: 768px) { 
    .skills-page {
        .App-logo {
            display: none;
        }
        h1 {
            text-align: center;
            font-size: 40px;
        }
        h2 {
            color: #3D550C;
            font-size: 14px;
        }
        .skills-text {
            flex-direction: column;
            .row {
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                p {
                    font-size: 12px;
                }
            }
        }
        .card {
            height: 80vw;
            width: 80vw;
            padding: 20px;
            .card-text {
                padding: 4px;
            }
            .card-img {
                padding: 4px;
            }
            .methods {
                margin-left: 4px;
                display: flex;
                padding: 4px;
                div {
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    background-color: $lightgreen;
                    color: #fff;
                    font-size: 8px;
                    text-align: center;
                }
            }
        }
    }
    .long-desc {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) { 
    .skills-page {
        .App-logo {
            display: none;
        }
        h1 {
            text-align: center;
            font-size: 40px;
        }
        h2 {
            color: #3D550C;
            font-size: 14px;
        }
        .skills-text {
            flex-direction: column;
            .row {
                flex-direction: column;
                p {
                    font-size: 12px;
                }
            }
        }
        .card {
            height: 40vw;
            width: 40vw;
            padding: 20px;
            .card-text {
                padding: 4px;
            }
            .card-img {
                padding: 4px;
            }
            .methods {
                margin-left: 4px;
                display: flex;
                padding: 4px;
                div {
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    background-color: $lightgreen;
                    color: #fff;
                    font-size: 8px;
                    text-align: center;
                }
            }
        }
    }
    .long-desc {
        display: none;
    }
}