@import "variables";
@import "styles";

.about-page {
    padding: 0 40px;
    h1 {
        color: #3D550C;
        padding: 20px;
        font-size: 44px;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 10vh;
    }
    .about-text {
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        img {
            height: 400px;
        }
        .col {
            width: 50vw;
            padding: 0 20px;
            p {
                color: $darkgreen;
                font-size: 18px;
            }
            a {
                text-decoration: none;
                font-weight: 700;
                color: #3D550C;
                &:hover {
                    color: #e2806a;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .about-page {
        overflow-x: hidden;
        width: 80vw;
        text-align: center;
        h1 {
            font-size: 40px;
        }
        .about-text {
            flex-direction: column;
            img {
                height: 200px;
            }
            .col {
                width: 100%;
                padding: 20px 40px;
                p {
                    font-size: 14px;
                }
            }
        }
    }
}