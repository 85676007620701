.col {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.copyright {
    display: flex;
    justify-content: center;
    .svg-inline--fa.fa-heart.fa-w-16 {
        color: pink;
    }
    p {
        color: #012b09;
        font-size: 20px;
    }
    a {
        text-decoration: none;
        font-weight: 700;
        color: #012b09;
        &:hover {
            color: #e2806a;
        }
    }
    .hiker {
        height: 80px;
        padding-left: 20px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

.find-me-sec {
    display: flex;
    justify-content: center;
    h1 {
        color: #3D550C;
        font-size: 44px;
    }
    .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        svg {
            padding: 0 10px;
            font-size: 40px;
            color: #3D550C;
            opacity: .8;
            &:hover {
                color: #e2806a;
            }
        }
    }
}

@media (max-width: 1024px) { 
    .copyright {
        padding: 20px;
        text-align: center;
        margin-top: 20px;
        img {
            height: 100px;
        }
        p {
            opacity: .8;
        }
    }
    .find-me-sec {
        align-items: center;
        margin-left: 0;
        height: unset;
        h1 {
            font-size: 40px;
            margin: 20px 0;
        }
        .icons {
            height: unset;
            width: 100%;
            margin: 20px;
            svg {
                font-size: 40px;
            }
        }
    }
}

@media (min-width: 1024px) {
    .copyright {
        p {
            text-align: center;
        }
        img {
        }
    }
}